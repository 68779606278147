<template>
  <div>
    <b-modal
      ref="moveback-modal"
      id="moveback-modal"
      title="Moveback"
      size="lg"
      hide-footer
      no-close-on-backdrop
    >
      <b-card-text>
        <!--- START (เลขที่ใบงาน) --->
        <b-row>
          <b-col md="6" class="">
            <label>เลขที่ใบงาน</label>
            <b-form-input
              id="smallInput"
              size="sm"
              placeholder="VST-230911"
              readonly
              v-model="ticketData.ticketNo"
            />
          </b-col>
          <b-col md="6" class="">
            <label>ส่งให้ช่าง</label>
            <b-form-input
              id="smallInput"
              size="sm"
              placeholder="ช่าง"
              readonly
              v-model="ticketData.assignTo.fullname"
            />
          </b-col>
        </b-row>
        <!--- END (เลขที่ใบงาน) --->

        <!--- START (Serial) --->
        <b-row class="mt-2">
          <b-col md="6" class="">
            <label>Serial No.</label>
            <b-form-input
              id="smallInput"
              size="sm"
              placeholder="1804HJI093DJJ09921"
              v-model.trim="movebackData.serial"
              @keyup="searchStockBySerial"
            />
          </b-col>
          <b-col md="6" class="pt-2">
            <div v-if="isSearch">Searching...</div>
            <div v-else>
              <span
                style="color: rgb(29 126 29)"
                v-if="searchSerialData.isFound"
                ><feather-icon icon="CheckCircleIcon" /> พบใน
                {{ searchSerialData.from }}</span
              >
              <span
                style="color: rgb(29 126 29)"
                v-if="
                  searchSerialData.isFound == undefined ||
                  (movebackData.serial && movebackData.serial.length < 3)
                "
              ></span>
              <span
                style="color: rgb(192 35 35)"
                v-if="
                  searchSerialData.isFound==false &&
                  movebackData.serial &&
                  movebackData.serial.length > 3
                "
                ><feather-icon icon="XCircleIcon" /> ไม่พบ Stock</span
              >
            </div>
          </b-col>
        </b-row>
        <!--- END (Serial) --->

        <!--- START (เลขที่ใบงาน) --->
        <b-row class="mt-1">
          <b-col md="4" class="">
            <label>Hardware type</label>
            <v-select
              id="brand"
              v-model="hwTypeSelected"
              label="hwTypeName"
              :options="hwTypeOption"
              class="select-size-sm"
              :clearable="false"
              @input="onChangeHwType"
              :disabled="searchSerialData.isFound"
            />
          </b-col>
          <b-col md="4" class="">
            <label>Brand</label>
            <v-select
              id="brand"
              v-model="hwBrandSelected"
              label="hwBrandName"
              :options="hwBrandOption"
              class="select-size-sm"
              :clearable="false"
              @input="onChangeHwBrand"
              :disabled="searchSerialData.isFound"
            />
          </b-col>
          <b-col md="4" class="">
            <label>Model</label>
            <v-select
              id="brand"
              v-model="hwModelSelected"
              label="hwModelName"
              :options="hwModelOption"
              class="select-size-sm"
              :clearable="false"
              :disabled="searchSerialData.isFound"
            />
          </b-col>
        </b-row>
        <!--- END (เลขที่ใบงาน) --->

        <!--- START (หมายเหตุ) --->
        <b-row class="mt-1">
          <b-col md="12" class="">
            <label>หมายเหตุ</label>
            <b-form-textarea
              id="textarea-default"
              placeholder="หมายเหตุ"
              rows="2"
              size="sm"
              v-model="movebackData.detail"
            />
          </b-col>
        </b-row>
        <!--- END (หมายเหตุ) --->

        <!--- START (BTN SUBMIT) --->
        <b-row class="mt-1">
          <b-col md="12" class="d-flex justify-content-end">
            <b-button variant="primary" size="sm" @click="saveMoveback" :disabled="isLoading || !movebackData.serial"
              >
              <span v-if="isLoading">กำลังบันทึก...</span>
              <span v-else>บันทึก</span>
              </b-button
            >
          </b-col>
        </b-row>
        <!--- END (BTN SUBMIT) --->
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
//SERVICES
import HardwareService from "@/services/setting/hardware";
import MovebackService from "@/services/stock/moveback";

import {
  VBModal,
  BCardText,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  props: ["ticketData"],
  components: {
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    vSelect,
    BButton,
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      hwTypeSelected: {},
      hwBrandSelected: {},
      hwModelSelected: {},
      hwTypeOption: [],
      hwBrandOption: [],
      hwModelOption: [],
      movebackData: {},
      userLogin: {},
      searchSerialData: {},
      isSearch: false,
      timer: null,
      isLoading:false
    };
  },
  async created() {
    const userLoginData = localStorage.getItem("userData");
    const userData = JSON.parse(userLoginData);
    this.userLogin = userData;
    await this.getHwType();
  },
  methods: {
    async saveMoveback() {
      const obj = {
        ...this.movebackData,
        hwTypeId: this.hwTypeSelected.id,
        hwBrandId: this.hwBrandSelected.id,
        hwModelId: this.hwModelSelected.id,
        assignTo: this.ticketData.assignTo.userId,
        createBy: this.userLogin.userId,
        ticketNo: this.ticketData.ticketNo,
        branchId: this.ticketData.customerId,
      };

      this.isLoading = true
      const result = await MovebackService.InsertMovebackStock(obj);
      if (result.status == "success") {
            this.isSelectIncDetailAll = false
            this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Moveback`,
              icon: "CheckIcon",
              variant: "success",
              text: `นำกลับเครื่องเรียบร้อย`,
            },
          });
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Moveback`,
                icon: "XIcon",
                variant: "danger",
                text: `นำกลับเครื่องผิดพลาด`,
              },
            });
          }

      
      this.isLoading = false
      this.hideModal();
    },
    async searchStockBySerial(e) {
      let serial = e.target.value;
      if (serial.length < 3) return;

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        this.isSearch = true;

        const result = await MovebackService.CheckStockBySerial(serial || "");
        this.searchSerialData = result.data;

        const item = result.data
        if(item.isFound){
          
          this.hwTypeSelected = {id:item.hwTypeId, hwTypeName:item.hwTypeName}
          this.hwBrandSelected = {id:item.hwBrandId, hwBrandName:item.hwBrandName}
          this.hwModelSelected = {id:item.hwModelId, hwModelName:item.hwModelName}
        }

        this.isSearch = false;
      }, 300);
    },
    async getHwType() {
      const result = await HardwareService.GetHwTypeList();
      this.hwTypeOption = result.data;

      if (result.data.length > 0) {
        this.hwTypeSelected = result.data[0];
        await this.onChangeHwType(result.data[0]);
      } else {
        this.hwTypeSelected = {};
      }
    },

    async getHwBrand(typeId) {
      const result = await HardwareService.GetHwBrandList(typeId);
      this.hwBrandOption = result.data;
      if (result.data.length > 0) {
        this.hwBrandSelected = result.data[0];
        await this.onChangeHwBrand(result.data[0]);
      } else {
        this.hwBrandSelected = {};
      }
    },

    async getHwModel(brandId) {
      const result = await HardwareService.GetHwModelList(brandId);
      this.hwModelOption = result.data;
      if (result.data.length > 0) {
        this.hwModelSelected = result.data[0];
      } else {
        this.hwModelSelected = {};
      }
    },

    async onChangeHwType(item) {
      await this.getHwBrand(item.id);
    },

    async onChangeHwBrand(item) {
      await this.getHwModel(item.id);
    },

    hideModal() {
      this.$refs["moveback-modal"].hide();
    },
  },
};
</script>

<style>
@import "./style.css";
</style>
