<template>
  <div>
    <b-modal
      id="select-customer-modal"
      size="xl"
      hide-footer
      no-close-on-backdrop
    >
      <b-card-text>
        <!--- START (ลูกค้า) --->
        <b-row>
          <b-col md="6" class="">
            <label>บริษัท</label>
            <v-select
              id="customer"
              v-model="customerCompanySelected"
              label="companyName"
              :options="customerCompanyOption"
              class="select-size-sm"
              :clearable="false"
            />
          </b-col>
          <b-col md="6" class="">
            <label>Brand</label>
            <v-select
              id="brand"
              v-model="customerSubCompanySelected"
              label="subCompanyName"
              :options="customerSubCompanyOption"
              class="select-size-sm"
              :clearable="false"
            />
          </b-col>
        </b-row>
        <!--- END (ลูกค้า) --->

        <!-- START ROW Log -->
        <b-row class="mt-2">
          <b-col md="12" class="my-1" v-if="branchList.length>0">
            <b-form inline class="d-flex justify-content-start" >
              <b-input-group class="input-group-merge" style="width: 30%;">
                <b-form-input
                  id="basic-password1"
                  type="text"
                  placeholder="ค้นหาสาขา..."
                  v-model="filter"
                />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" class="cursor-pointer" />
                </b-input-group-append>
              </b-input-group>
            </b-form>
          </b-col>

          <b-col md="12 ">
            <b-table
              class="shadow"
              style="font-size: 12px"
              small
              striped
              bordered
              hover
              :items="filteredBranchList"
              :fields="fields"
              :busy="isLoading"
              selectable
              select-mode="single"
            
             
              @row-selected="onSelectBranch"
            >
              <template #cell(no)="row">
                {{ row.index + 1 }}
              </template>
              <template #cell(selected)="{ rowSelected }"></template>

              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>      
        <!-- END ROW Log -->
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { VBModal, BCardText, BRow, BCol, BTable, BInputGroup,BFormInput, BInputGroupAppend } from "bootstrap-vue";
import vSelect from "vue-select";

import CustomerService from "@/services/setting/customer";

export default {
  props: ["customerCompanyOption"],
  components: { BCardText, BRow, BCol, vSelect, BTable, BInputGroup,BFormInput, BInputGroupAppend },
  directives: {
    "b-modal": VBModal,
  },
  computed: {
    dialog() {
      return this.isShowModal;
    },
  },
  watch: {
    dialog() {
      return this.isShowModal;
    },
  },
  data() {
    return {
      customerCompanySelected: {},
      customerSubCompanySelected: {},
      customerSubCompanyOption: [],
      fields: [
        {
          key: "no",
          label: "ลำดับ",
          sortable: true,
          thStyle: {
            width: "50px !important",
            textAlign: "center",
            color: "white",
          },
        },

        {
          key: "branchCode",
          label: "รหัสลูกค้า",
          sortable: false,
          thStyle: { textAlign: "center", color: "white" },
        },
        {
          key: "branchName",
          label: "ชื่อลูกค้า",
          sortable: false,
          thStyle: { textAlign: "center", color: "white" },
        },
        {
          key: "address",
          label: "ที่อยู่",
          sortable: false,
          thStyle: { textAlign: "center", color: "white" },
        },
      ],
      branchList: [],      
      isLoading:false,
      filter: null,
    
    };
  },

  watch: {
    customerCompanyOption() {
      this.customerCompanySelected = this.customerCompanyOption[0];
    },
    customerCompanySelected() {
      this.getCustomerSubCompanyList();
    },
    customerSubCompanySelected() {
      this.getCustomerBranchList();
    },
    
  },
    computed: {
    customerRolePermission() {
      const permission =  this.$store.getters["user/getCustomerRolePermission"]("ST005-1");
      return permission
    }, 
    filteredBranchList() {
      const includesFields = ['branchCode', 'branchName', 'address'];
      
      if (!this.filter || this.filter.length <= 1) return this.branchList;

      const filter = this.filter.toLowerCase();

      return this.branchList.filter(branch => {
        return includesFields.some(field => {
          const value = branch[field];
          return String(value).toLowerCase().includes(filter);
        });
      });
    }
  },
  methods: {
    async getCustomerSubCompanyList() {
      try {
        if(!this.customerCompanySelected.id && this.customerCompanySelected.id==0){
          this.customerSubCompanyOption = [{id:0, subCompanyName:"=== เลือก Brand ==="}];
          this.customerSubCompanySelected = this.customerSubCompanyOption[0];
          this.branchList= []
          return
        }

        this.isLoading = true
        const result = await CustomerService.GetCustomerSubCompanyList(
          this.customerCompanySelected.id,
          {}
        );
        this.customerSubCompanyOption = [{id:0, subCompanyName:"=== เลือก Brand ==="},...result.data.data];

        //IF Have Role Permission For Customer
        if(this.customerRolePermission){
          if(Object.keys(this.customerRolePermission).length > 0 && this.customerRolePermission.subCompanyId!==0){
            this.customerSubCompanyOption = this.customerSubCompanyOption.filter(option=>option.id == this.customerRolePermission.subCompanyId)
          }
        }

        this.customerSubCompanySelected = this.customerSubCompanyOption[0];
        this.isLoading = false
      } catch (error) {
        console.log("can't get customer subcompany");
      }
    },
    async getCustomerBranchList() {
      try {
          if(!this.customerSubCompanySelected.id && this.customerSubCompanySelected.id==0){
            this.branchList= []
            return
          }

        this.isLoading = true
        const result = await CustomerService.GetCustomerBranchList(
          this.customerSubCompanySelected.id, 
          {},
          ""
        );
        this.branchList = result.data.data;
        
        this.isLoading = false
      } catch (error) {
        console.log("can't get customer branch");
      }
    },

    onSelectBranch(item) {      
      this.$emit("emitBranchSelected",item);
      this.$bvModal.hide("select-customer-modal")
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },

  mounted() {},
};
</script>

<style>
@import "./style.css";
</style>
