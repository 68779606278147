<template>
  <div>
    <b-modal
      ref="setup-modal"
      id="setup-modal"
      title="Stock Onhand"
      size="xl"
      hide-footer
    >
      <b-card-text>
        <!-- START ROW Log -->
        <b-row>
          <b-col md="12" class="">
            <b-form inline class="d-flex justify-content-end">
              <b-input-group class="input-group-merge" style="width: 40%">
                <b-form-input
                  id="basic-password1"
                  type="text"
                  placeholder="ค้นหา..."
                  v-model="filter"
                />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" class="cursor-pointer" />
                </b-input-group-append>
              </b-input-group>
            </b-form>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="12 ">
            <b-table
              class="shadow"
              style="font-size: 12px"
              small
              striped
              bordered
              hover
              :items="filteredSetupList"
              :fields="fields"
              :busy="isLoading"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Loading...</strong>
                </div>
              </template>
              <template #cell(onhandDate)="row">
                <center>
                  {{ convertDate(row.item.onhandDate, "original") }}
                </center>
              </template>
              <template #cell(action)="row">
                <center>
                  <b-button
                    class="btn btn-primary btn-sm"
                    @click="onSetup(row.item)"
                    >Setup</b-button
                  >
                </center>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <!-- END ROW Log -->
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  VBModal,
  BCardText,
  BRow,
  BCol,
  BTable,
  BSpinner,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
} from "bootstrap-vue";
import vSelect from "vue-select";

//SERVICES
import OnhandStockService from "@/services/stock/onhand";
import SetupStockService from "@/services/stock/setup";
import moment from "moment";

export default {
  props: ["engineerId", "isShowSetup", "ticketNo"],
  components: {
    BCardText,
    BRow,
    BCol,
    vSelect,
    BTable,
    BSpinner,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      fields: [
        {
          key: "serial",
          label: "Serial No.",
          sortable: true,
          thStyle: {
            width: "150px !important",
            textAlign: "center",
            color: "white",
          },
        },

        {
          key: "hwTypeName",
          label: "Type",
          sortable: false,
          thStyle: { textAlign: "center", color: "white" },
        },
        {
          key: "hwBrandName",
          label: "Brand",
          sortable: false,
          thStyle: { textAlign: "center", color: "white" },
        },
        {
          key: "hwModelName",
          label: "Model",
          sortable: false,
          thStyle: { textAlign: "center", color: "white" },
        },
        {
          key: "branchName",
          label: "From stock",
          sortable: false,
          thStyle: { textAlign: "center", color: "white" },
        },
        {
          key: "detail",
          label: "Detail",
          sortable: false,
          thStyle: {
            textAlign: "center",
            color: "white",
            width: "15% !important",
          },
        },
        {
          key: "onhandDate",
          label: "Date onhand",
          sortable: false,
          thStyle: {
            textAlign: "center",
            color: "white",
            width: "10% !important",
          },
        },
        {
          key: "action",
          label: "Action",
          sortable: false,
          thStyle: {
            textAlign: "center",
            color: "white",
            width: "8% !important",
          },
        },
      ],
      items: [],
      isLoading: false,
      userLogin: {},
      filter: null,
    };
  },

  created() {
    const userLoginData = localStorage.getItem("userData");
    const userData = JSON.parse(userLoginData);
    this.userLogin = userData;
  },

  computed: {
    filteredSetupList() {
      const includesFields = ["serial", "hwTypeName", "hwBrandName", "hwModelName","branchName","detail","onhandDate"];

      if (!this.filter || this.filter.length <= 1) return this.items;

      const filter = this.filter.toLowerCase();

      return this.items.filter((onhand) => {
        return includesFields.some((field) => {
          const value = onhand[field];
          return String(value).toLowerCase().includes(filter);
        });
      });
    },
  },

  watch: {
    isShowSetup(data) {
      this.getOnhandList();
    },
  },
  methods: {
    async getOnhandList() {
      this.isLoading = true;
      const result = await OnhandStockService.GetOnhandStockByEngineerId(
        this.engineerId
      );
      this.items = result.data.data;
      this.isLoading = false;
    },
    async onSetup(item) {
      const isConfirm = confirm("ต้องการติดตั้งเครื่องนี้หรือไม่ ?");
      if (!isConfirm) return;

      const obj = {
        ...item,
        ticketNo: this.ticketNo,
        createBy: this.userLogin.userId,
      };

      const resut = await SetupStockService.InsertSetupStock(obj);
      await this.getOnhandList();
    },
    convertDate(date, type = "") {
      if (type == "date") {
        return moment(date).format("yyyy-MM-DD");
      }

      if (type == "original") {
        return moment(date).subtract(7, "h").format("yyyy-MM-DD HH:mm:ss");
      }
      return moment(date).format("yyyy-MM-DD HH:mm:ss");
    },
    hideModal() {
      this.$refs["setup-modal"].hide();
    },
  },
};
</script>

<style>
@import "./style.css";
</style>
