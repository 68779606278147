<template>
  <div>
    <!--- Navigator-->
    <b-row>
      <b-col cols="3">
        <h3><b>Ticket</b></h3>
        <small>
          <router-link to="/ticket/inprogress">Ticket</router-link> >
          <b v-if="mode == 'edit'">แก้ไขใบงาน</b>
          <b v-else-if="mode == 'create'">สร้างใบงาน</b>
          <b v-else>รายละเอียดใบงาน</b>
        </small>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-center">
        <b-button
          variant="primary"
          size="sm"
          style="height: 35px"
          class="mr-1"
          @click="onSaveTicket"
          v-if="
            ticketPermission == 'edit' ||
            (mode == 'create' && ticketPermission == 'create')
          "
        >
          <b-spinner
            v-if="isLoading.default"
            small
            label="Spinning"
          ></b-spinner>
          <feather-icon icon="SaveIcon" v-else />

          บันทึก
        </b-button>

        <b-button
          variant="outline-dark"
          size="sm"
          @click="$router.go(-1)"
          style="height: 35px"
        >
          <feather-icon icon="ArrowLeftIcon" />
          ย้อนกลับ
        </b-button>
      </b-col>
    </b-row>
    <!--- Navigator-->

    <b-row class="mt-2">
      <b-col cols="12">
        <b-overlay
          :show="isLoading.default"
          rounded="lg"
          opacity="0.55"
          blur="2px"
          variant="transparent"
          fixed
        >
          <b-card>
            <!--- START (ลูกค้า) --->
            <b-row>
              <b-col md="3" class="">
                <label><b>ลูกค้า</b></label>
                <v-select
                  id="customer"
                  v-model="customerSelected"
                  label="ownerName"
                  :options="customerOption"
                  item-text="ownerName"
                  item-value="id"
                  class="select-size-sm"
                  :clearable="false"
                 
                  
                />
              </b-col>
              <b-col col>
                <label></label>
                <div v-if="ticketData.customer.remark" class="p-1 rounded text-center" style="background-color:#c42525;color:white" show>
                  <feather-icon  icon="AlertCircleIcon" size="20"/>
                  {{ticketData.customer.remark}}</div>
              </b-col>
              <b-col md="3">
                <label><b>เลขที่ใบงาน</b></label>
                <b-form-input
                  id="smallInput"
                  size="sm"
                  placeholder="VST-23000001"
                  v-model="ticketData.ticketNo"
                  :disabled="
                    mode == 'close' || mode == 'edit' || mode == 'cancel'
                  "
                />
              </b-col>
            </b-row>
            <!--- END (ลูกค้า) --->

            <!--- START ROW รหัสสาขา --->
            <b-row class="mt-1">
              <b-col md="3" class="">
                <label><b>รหัสสาขา</b></label>
                <b-form-input
                  id="smallInput"
                  size="sm"
                  placeholder="รหัสสาขา"
                  readonly
                  v-model="ticketData.customer.branchCode"
                />
              </b-col>
              <b-col md="6" class="">
                <label
                  ><b>ชื่อสาขา <span class="text-danger">*</span></b></label
                >
                <b-input-group>
                  <b-input-group-prepend
                    is-text
                    @click="onClickFindCustomerModal"
                    :disabled="isLoadCustomer"
                    style="cursor: pointer"
                  >
                    <b-spinner
                      v-if="isLoadCustomer"
                      class="align-middle"
                      small
                      variant="primary"
                    ></b-spinner>
                    <feather-icon v-else icon="SearchIcon" />
                  </b-input-group-prepend>

                  <b-form-input
                    placeholder="ชื่อสาขา"
                    size="sm"
                    readonly
                    v-model="ticketData.customer.branchName"
                  />
                </b-input-group>
              </b-col>
              <b-col md="3" class="">
                <label><b>วันหมดประกัน </b> <span v-if="checkExpireWarranty(ticketData.customer.warrantyExpireDate)!==''"  :class="{'text-danger' : isWarrantyExpire ,'text-success':!isWarrantyExpire}"><b>  ({{checkExpireWarranty(ticketData.customer.warrantyExpireDate)}})</b></span></label>
                <b-form-input
                  id="smallInput"
                  size="sm"
                  placeholder="วันหมดประกัน"
                  readonly
                  :value="(ticketData.customer.warrantyExpireDate && ticketData.customer.warrantyExpireDate!=='0000-00-00') ?  showDate(ticketData.customer.warrantyExpireDate) :''"
                  
                />
              </b-col>
            </b-row>
            <!--- END รหัสสาขา --->

            <!--- START ROW ที่อยู่ --->
            <b-row class="mt-1">
              <b-col md="9">
                <label><b>ที่อยู่</b></label>
                <b-form-input
                  id="smallInput"
                  size="sm"
                  placeholder="ที่อยู่"
                  readonly
                  v-model="ticketData.customer.address"
                />
              </b-col>
              <b-col md="3">
                <label><b>อ้างอิงใบงาน</b></label>
                <b-form-input
                  id="smallInput"
                  size="sm"
                  placeholder="อ้างอิงใบงาน"
                  v-model="ticketData.refTicketNo"
                 
                />
              </b-col>
            </b-row>
            <!--- END ที่อยู่ --->

            <!--- START ROW Location --->
            <b-row class="mt-1">
              <b-col md="7" class="">
                <label
                  ><b>Location <span class="text-danger">*</span></b></label
                >
                <b-input-group>
                  <b-form-input
                    placeholder="Location"
                    size="sm"
                    readonly
                    v-model="location"
                    
                   
                  />
                  <b-input-group-prepend
                    is-text
                    @click="copyLocation"
                    style="cursor: pointer; border-right:1px solid #ccc !important;"
                  >
                    <feather-icon icon="CopyIcon" />
                  </b-input-group-prepend>
                </b-input-group>
                  
              </b-col>
            
              <b-col md="2">
                <label>.</label>
                <b-form-checkbox
                  value="1"
                  v-model="ticketData.isSendMsr"
                 
                >
                  <small><b>ส่งเอกสาร MSR.</b></small>
                </b-form-checkbox>
              </b-col>
              <b-col md="3">
                <label><b>เลขที่ใบ MSR</b></label>
                <b-form-input
                  id="smallInput"
                  size="sm"
                  placeholder="เลขที่ใบ MSR"
                  v-model="ticketData.msrNo"
                 
                />
              </b-col>
            </b-row>
            <!--- END Location --->
          </b-card>
        </b-overlay>

        <!-- BORDER -->
        <!-- <div class="mt-3 border-bottom"></div> -->
        <b-overlay
          :show="isLoading.default"
          rounded="lg"
          opacity="0.55"
          blur="2px"
          variant="transparent"
        >
          <b-card>
            <!-- START ROW ปัญหา -->
            <b-row class="mt-1">
              <b-col md="3" class="">
                <label><b>ประเภทของปัญหา</b></label>
                <v-select
                  id="problemType"
                  v-model="ticketData.problemType"
                  label="title"
                  :options="problemTypeOption"
                  class="select-size-sm"
                  :clearable="false"
               
                />
              </b-col>
              <b-col md="3"></b-col>
              <b-col md="3">
                <label><b>ผู้แจ้ง</b></label>
                <b-form-input
                  id="smallInput"
                  size="sm"
                  placeholder="ผู้แจ้ง"
                  v-model="ticketData.contactName"
                
                />
              </b-col>
              <b-col md="3">
                <label><b>เบอร์โทร</b></label>
                <b-form-input
                  id="smallInput"
                  size="sm"
                  placeholder="เบอร์โทร"
                  v-model="ticketData.contactTel"
                 
                />
              </b-col>
            </b-row>
            <!-- END ROW ปัญหา -->

            <!-- START ROW รายละเอียดปัญหา -->
            <b-row class="mt-1">
              <b-col md="12">
                <label
                  ><b
                    >รายละเอียดปัญหา <span class="text-danger">*</span></b
                  ></label
                >
                <b-form-textarea
                  id="textarea-default"
                  placeholder="รายละเอียดปัญหา"
                  rows="2"
                  size="sm"
                  v-model="ticketData.problemDetail"
                  
                />
              </b-col>
            </b-row>
            <!-- END ROW รายละเอียดปัญหา -->

            <!-- START ROW ระดับความรุนแรง -->
            <b-row class="mt-1">
              <b-col md="2" class="">
                <label><b>ระดับความรุนแรง</b></label>
                <v-select
                  id="problemType"
                  v-model="ticketData.catLevel"
                  label="title"
                  :options="catLevelOption"
                  class="select-size-sm"
                  :clearable="false"
               
                />
              </b-col>
              <b-col md="2">
                <label><b>ประเภทการชำระเงิน</b></label>
                <v-select
                  id="problemType"
                  v-model="ticketData.ticketType"
                  label="title"
                  :options="ticketTypeOption"
                  class="select-size-sm"
                  :clearable="false"
                 
                />
              </b-col>
              <b-col md="6"></b-col>
              <b-col md="2">
                <label><b>ระยะเวลาแก้ไข (ชั่วโมง) </b></label>
                <v-select
                  id="problemType"
                  v-model="ticketData.catTime"
                  label="title"
                  :options="timeResolveOption"
                  class="select-size-sm"
                  :clearable="false"
                  disabled
                />
              </b-col>
            </b-row>
            <!-- END ROW ระดับความรุนแรง -->

            <!-- START ROW ช่างรับใบงาน -->
            <b-row class="mt-1">
              <b-col md="3" class="">
                <label><b>ผู้รับใบงาน</b></label>
                <v-select
                  id="problemType"
                  v-model="ticketData.assignTo"
                  label="fullname"
                  :options="employeeOption"
                  class="select-size-sm"
                  :clearable="false"
                  :disabled="customerRolePermission&&customerRolePermission.selectEngineerMode=='0'"
                 
                />
              </b-col>
              <b-col md="4" class="pt-2 d-flex justify-content-start">
                <b-button
                  size="sm"
                  variant="dark"
                  class="mr-1"
                  @click="onSetup"
                  
                  v-if="ticketPermission == 'edit'"
                  >Setup</b-button
                >
                <b-button
                  size="sm"
                  variant="dark"
                
                  @click="onMoveback"
                  v-if="ticketPermission == 'edit'"
                  >Moveback</b-button
                >
              </b-col>
              <b-col md="2" class="">
                <label><b>แก้ไขโดย</b></label>
                <v-select
                  id="problemType"
                  v-model="ticketData.problemSolvingBy"
                  label="title"
                  :options="resolveByOption"
                  class="select-size-sm"
                  :clearable="false"
                
                />
              </b-col>

              <b-col md="3">
                <label><b>ผู้เปิดใบงาน</b></label>

                <v-select
                  id="problemType"
                  v-model="ticketData.receiveBy"
                  label="fullname"
                  :options="employeeOption"
                  class="select-size-sm"
                  :clearable="false"
                  :disabled="customerRolePermission&&customerRolePermission.selectEngineerMode=='0'"
                 
                />
              </b-col>
            </b-row>
            <!-- END ROW ช่างรับใบงาน -->

            <!-- START ROW วิธีการแก้ไขปัญหา -->
            <b-row class="mt-1">
              <b-col md="12">
                <label><b>วิธีการแก้ไขปัญหา</b></label>
                <b-form-textarea
                  id="textarea-default"
                  placeholder="วิธีการแก้ไขปัญหา"
                  rows="2"
                  size="sm"
                  v-model="ticketData.problemSolving"
                 
                />
              </b-col>
            </b-row>
            <!-- END ROW วิธีการแก้ไขปัญหา -->

            <!-- START ROW หมายเหตุ -->
            <b-row class="mt-1">
              <b-col md="12">
                <label><b>หมายเหตุ</b></label>
                <b-form-input
                  id="smallInput"
                  size="sm"
                  placeholder="หมายเหตุ"
                  v-model="ticketData.remark"
                 
                />
              </b-col>
            </b-row>
            <!-- END ROW หมายเหตุ -->

            <!-- START ROW เวลาเปิดใบงาน -->
            <b-row class="mt-1">
              <b-col md="3">
                <table border="0" style="font-size: 12px">
                  <tr>
                    <td><b>เปิดใบงาน : </b></td>
                    <td width="5%"></td>
                    <td>
                      <b-form-checkbox
                        id="checkbox-1"
                        v-model="ticketDate.open.status"
                        :disabled="ticketDate.open.readOnly"
                      >

                     
                        <!-- <small>{{
                          showDate(
                            ticketDate.open.value,
                            mode == "edit" ? true : false
                          )
                        }}</small> -->

                        <small v-if="ticketDate.open.readOnly">{{
                          
                          showDate(
                            ticketDate.open.value,
                            ticketDate.open.readOnly ? true : false
                          )
                        }}</small>
                        <small v-else>{{
                           showDate(
                            ticketDate.open.value,
                            mode == "edit" ? true : false
                          )
                        }}</small>

                      </b-form-checkbox>
                    </td>
                  </tr>
                  <tr>
                    <td><b>รับงาน : </b></td>
                    <td width="5%"></td>
                    <td class="text-muted">
                      <b-form-checkbox
                        id="checkbox-open"
                        v-model="ticketDate.receive.status"
                        :disabled="ticketDate.receive.readOnly"                        
                      >
                        <!-- <small>{{
                          showDate(
                            ticketDate.receive.value,
                            mode == "edit" ? true : false
                          )
                        }}</small> -->

                        <small v-if="ticketDate.receive.readOnly">{{
                          showDate(
                            ticketDate.receive.value,
                            ticketDate.receive.readOnly ? true : false
                          )
                        }}</small>
                        <small v-else>{{
                          showDate(
                            ticketDate.receive.value,
                            mode == "create" ? true : false
                          )
                        }}</small>

                        
                      </b-form-checkbox>
                    </td>
                  </tr>
                  <tr>
                    <td><b>แจ้งถึง : </b></td>
                    <td width="5%"></td>
                    <td class="text-muted">
                      <b-form-checkbox
                        id="checkbox-arrive"
                        v-model="ticketDate.arrive.status"
                        @change="validateFlowCheckTime('arrive')"
                        :disabled="ticketDate.arrive.readOnly"
                      >
                        <small v-if="ticketDate.arrive.readOnly">{{
                          showDate(
                            ticketDate.arrive.value,
                            ticketDate.arrive.readOnly ? true : false
                          )
                        }}</small>
                        <small v-else>{{
                          showDate(
                            ticketDate.arrive.value,
                            mode == "create" ? true : false
                          )
                        }}</small>
                      </b-form-checkbox>
                    </td>
                  </tr>
                  <tr>
                    <td><b>ปิดงาน : </b></td>
                    <td width="5%"></td>
                    <td class="text-muted">
                      <b-form-checkbox
                        id="checkbox-close"
                        v-model="ticketDate.close.status"
                        @change="validateFlowCheckTime('close')"
                        :disabled="ticketDate.close.readOnly"
                      >
                        <small v-if="ticketDate.close.readOnly">{{
                          showDate(
                            ticketDate.close.value,
                            ticketDate.close.readOnly ? true : false
                          )
                        }}</small>
                        <small v-else>{{
                          showDate(
                            ticketDate.close.value,
                            mode == "create" ? true : false
                          )
                        }}</small>
                      </b-form-checkbox>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td width="5%"></td>
                    <td>
                      <b-button
                      v-if="
                        ticketPermission == 'edit'
                      "
                        variant="outline-primary"
                        class="mt-1"
                        size="sm"
                        v-b-modal.changeDate-modal
                        ><feather-icon icon="EditIcon" /> แก้ไขเวลา</b-button
                      >
                    </td>
                  </tr>
                </table>
              </b-col>
              <b-col md="4" v-if="mode == 'edit'">
                <b-form-textarea
                  id="jobPattern"
                  placeholder="Pattern ส่งงาน"
                  rows="4"
                  size="sm"
                  v-model="patternSendJob"
                />
                <b-button
                  class="mt-1"
                  variant="dark"
                  size="sm"
                  @click="copyJob"
                >
                  <feather-icon icon="CopyIcon" />
                  Copy
                </b-button>
              </b-col>
            </b-row>
            <!-- END ROW เวลาเปิดใบงาน -->

            <!-- START ROW สถานะใบงาน -->
            <b-row class="mt-1">
              <b-col md="3" class="">
                <label><b>สถานะใบงาน</b></label>
                <v-select
                  id="problemType"
                  v-model="ticketData.ticketStatus"
                  label="title"
                  :options="ticketStatusOption"
                  class="select-size-sm"
                  :clearable="false"
                />
              </b-col>
              <b-col md="2">
              
              </b-col>
              <b-col md="3"> </b-col>
              <b-col md="2">
                <label><b>อัตราค่าน้ำมัน</b></label>
                <b-form-input
                  id="smallInput"
                  size="sm"
                  readonly
                  placeholder="0"
                  v-model="ticketData.customer.gasRate"
                />
              </b-col>
              <b-col md="2">
                <label><b>ระดับบริการ</b></label>
                <b-form-input
                  id="smallInput"
                  size="sm"
                  placeholder="WSLA"
                  readonly
                  v-model="ticketData.slaStatus"
                />
              </b-col>
            </b-row>
            <!-- END ROW สถานะใบงาน -->

            <b-row class="mt-2 d-flex justify-content-start align-items-center">
              <b-col cols="1">
                <b-button
                  variant="primary"
                  size="sm"
                  style="height: 35px"
                  class="mr-1"
                  @click="onSaveTicket"
                  v-if="
                    ticketPermission == 'edit' ||
                    (mode == 'create' && ticketPermission == 'create')
                  "
                >
                  <b-spinner
                    v-if="isLoading.default"
                    small
                    label="Spinning"
                  ></b-spinner>
                  <feather-icon icon="SaveIcon" v-else />

                  บันทึก
                </b-button>
              </b-col>
              <b-col cols="1" v-if="
                    ticketPermission == 'edit' ||
                    (mode == 'create' && ticketPermission == 'create')
                  ">
              
                <b-form-checkbox
                  value="true"
                  v-model="ticketData.isNotSendMail"
                >
                  <small><b>ไม่ส่ง Email</b></small>
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-card>
        </b-overlay>

        <!-- Start Ticket Comment -->
        <b-card v-if="ticketData.ticketId">
          <!-- START ROW Log -->
          <b-row>
            <b-col md="12">
              <h3><b>ประวัติการ Comment</b></h3>
              <b-table
                class="shadow"
                style="font-size: 12px"
                small
                striped
                bordered
                hover
                :items="commentList"
                :fields="commentField"
                :busy="isLoading.comment"
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Loading...</strong>
                  </div>
                </template>

                <template #cell(no)="row">
                  <center>{{ row.index + 1 }}</center>
                </template>

                <template #cell(tckCmnDate)="row">
                  <center>{{ showDate(row.item.tckCmnDate) }}</center>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <!-- END ROW Log -->
        </b-card>
        <!-- End Ticket Comment -->

        <!-- Start Ticket Log -->
        <b-card v-if="ticketData.ticketId">
          <!-- START ROW Log -->
          <b-row>
            <b-col md="12">
              <h3>ประวัติการทำรายการของใบงาน</h3>
              <b-table
                class="shadow"
                style="font-size: 12px"
                small
                striped
                bordered
                hover
                :items="logList"
                :fields="logField"
                :busy="isLoading.log"
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong> Loading...</strong>
                  </div>
                </template>

                <template #cell(no)="row">
                  <center>{{ row.index + 1 }}</center>
                </template>

                <template #cell(updateDate)="row">
                  <center>{{ showDate(row.item.updateDate) }}</center>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <!-- END ROW Log -->
        </b-card>
        <!-- End Ticket Log -->
       
      </b-col>
      <SelectCustomerModal
        :customerCompanyOption="customerCompanyList"
        @emitBranchSelected="onSelectedBranch"
      />

      <SetupModal
        :isShowSetup="isShowSetup"
        :engineerId="ticketData.assignTo && ticketData.assignTo.userId"
        :ticketNo="ticketData.ticketNo"
      />
      <MovebackModal :ticketData="ticketData" />
      <ChangeTicketDate :ticketData="ticketData" />
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BButton,
  BTable,
  BFormCheckbox,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  BSpinner,
  BOverlay,
  BAlert
} from "bootstrap-vue";
import vSelect from "vue-select";
import SelectCustomerModal from "./modal/selectCustomerModal";
import SetupModal from "./modal/setupModal";
import MovebackModal from "./modal/movebackModal";
import ChangeTicketDate from "./modal/changeTicketDate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

//SERVICE
import CustomerService from "@/services/setting/customer";
import UserService from "@/services/user";
import TicketService from "@/services/ticket";
import CommentService from "@/services/comment";
import TicketLogService from "@/services/log";
import { GET_CURRENT_DATE } from "@/utils/";

import moment from "moment";

let INIT_TICKET = {};
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BButton,
    BFormInput,
    BInputGroup,
    BTable,
    BFormCheckbox,
    BFormTextarea,
    BInputGroupPrepend,
    BInputGroupAppend,
    BSpinner,
    BOverlay,
    vSelect,
    SelectCustomerModal,
    SetupModal,
    MovebackModal,
    ChangeTicketDate,
    BAlert
  },
  data() {
    return {
      isWarrantyExpire:null,
      customerSelected: {},
      customerOption: [],
      problemTypeOption: [
        { id: 1, title: "Hardware problem", value: 1 },
        { id: 3, title: "Software problem", value: 3 },
        { id: 4, title: "Setup hardware", value: 4 },
        { id: 5, title: "Setup software", value: 5 },
        { id: 7, title: "User problem", value: 7 },
        { id: 8, title: "Other problem", value: 8 },
      ],
      catLevelOption: [
        { title: "ระดับ 1", value: "CAT1" },
        { title: "ระดับ 2", value: "CAT2" },
        { title: "ระดับ 3", value: "CAT3" },
        { title: "ระดับ 4", value: "CAT4" },
      ],
      ticketTypeOption: [
        { title: "เหมาจ่าย", value: "packages" },
        { title: "per call", value: "per call" },
      ],
      timeResolveOption: [
        { title: 1, value: 1 },
        { title: 2, value: 2 },
        { title: 3, value: 3 },
        { title: 4, value: 4 },
      ],
      employeeOption: [],
      resolveByOption: [
        { title: "Helpdesk", value: "Helpdesk" },
        { title: "CM", value: "CM" },
      ],
      ticketStatusOption: [
        { title: "Inprogress", value: "inprogress" },
        { title: "Close", value: "close" },
        { title: "Cancel", value: "cancel" },
      ],

      isLoading: {
        comment: false,
        log: false,
        default: false,
      },

      customerCompanyList: [],
      branchSelected: {},
      ticketData: {
        customer: {},
        problemSolvingBy: { title: "Helpdesk", value: "Helpdesk" },
        problemType: { id: 1, title: "Hardware Problem", value: 1 },
        catLevel: { title: "ระดับ 1", value: "CAT1" },
        ticketType: { title: "เหมาจ่าย", value: "เหมาจ่าย" },
        catTime: { title: 4, value: 4 },
        receiveBy: {},
        assignTo: {},
        slaStatus: "WSLA",
        ticketStatus: { title: "Inprogress", value: "inprogress" },
    
       
      },
      commentList: [],
      commentField: [
        {
          key: "no",
          label: "ลำดับ",
          sortable: false,
          thStyle: { textAlign: "center", color: "white", width: "5%" },
        },
        {
          key: "commentBy",
          label: "ชื่อผู้ Comment",
          sortable: false,
          thStyle: { textAlign: "center", color: "white", width: "20%" },
        },
        {
          key: "detail",
          label: "รายละเอียด",
          sortable: false,
          thStyle: { textAlign: "center", color: "white" },
        },
        {
          key: "tckCmnDate",
          label: "วันที่",
          sortable: false,
          thStyle: { textAlign: "center", color: "white", width: "20%" },
        },
      ],
      logList: [],
      logField: [
        {
          key: "no",
          label: "ลำดับ",
          sortable: false,
          thStyle: { textAlign: "center", color: "white", width: "5%" },
        },
        {
          key: "updateBy",
          label: "ชื่อผู้ทำรายการ",
          sortable: false,
          thStyle: { textAlign: "center", color: "white", width: "20%" },
        },
        {
          key: "dataUpdate",
          label: "รายละเอียด",
          sortable: false,
          thStyle: { textAlign: "center", color: "white" },
        },
        {
          key: "updateDate",
          label: "วันที่",
          sortable: false,
          thStyle: { textAlign: "center", color: "white", width: "20%" },
        },
      ],
      mode: "create",
      ticketLevel: "CAT1",
      ticketDate: {
        open: { status: 0, value: new Date(), readOnly: true },
        receive: { status: 0, value: new Date(), readOnly: true },
        arrive: { status: 0, value: new Date(), readOnly: true },
        close: { status: 0, value: new Date(), readOnly: true },
      },
      isShowSetup: false,
      isLoadCustomer: false,
    };
  },
  async created() {
    this.isLoading.default = true;
    const userLoginData = localStorage.getItem("userData");
    const userData = JSON.parse(userLoginData);

    await this.getCustomerOwnerList();
    await this.getEmployeeList();

    //SET RECEIVER
    const findedReceiver = this.employeeOption.find(
      (f) => f.userId == userData.userId
    );
    if (Object.keys(findedReceiver).length > 0) {
      this.ticketData.receiveBy = findedReceiver;
      this.ticketData.assignTo = findedReceiver;
    }

    //WHEN EDIT MODE
    const paramTicketId = this.$route.params.ticketId;
    if (paramTicketId) {
      this.mode = "edit";
      
      this.getTicketById(paramTicketId);
      this.getTicketCommentList(paramTicketId);
      this.getTicketLogList(paramTicketId);
      
    }
    this.isLoading.default = false;
  },
  computed: {
    patternSendJob() {
      const text = `แจ้งงาน : ${this.ticketData.ticketNo}
รหัสสาขา ${this.ticketData.customer.branchCode}      
${this.ticketData.branchName}
${this.ticketData.problemDetail}`;
      return text;
    },
    catLevel() {
      return this.ticketData.catLevel;
    },
    ticketPermission() {
      const permission =  this.$store.getters["user/getPermissionPage"]("TK001");
      if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission
    },
    customerRolePermission() {
      const permission =  this.$store.getters["user/getCustomerRolePermission"]("ST005-1");
      return permission
    },    
    canCloseTicketStatus() {
      if (
        this.ticketDate.open.status &&
        this.ticketDate.receive.status &&
        this.ticketDate.arrive.status &&
        this.ticketDate.close.status
      ) {
        return true;
      }

      return false;
    },
    location(){
      const locationData = this.mode =="create" ?  this.ticketData.customer : this.ticketData
      if(locationData.latitude && locationData.longtitude){
        return `${locationData.latitude || ""},${locationData.longtitude || ""}`
      }else{
        return `${locationData.latitude || ""}`
      }
    }
  },
  watch: {
    customerSelected() {
      this.getCustomerCompanyList();
    },
    catLevel() {
      if (this.ticketData.catLevel.value == "CAT1") {
        this.ticketData.catTime = 4;
      }

      if (this.ticketData.catLevel.value == "CAT2") {
        this.ticketData.catTime = 8;
      }

      if (this.ticketData.catLevel.value == "CAT3") {
        this.ticketData.catTime = 12;
      }

      if (this.ticketData.catLevel.value == "CAT4") {
        this.ticketData.catTime = 24;
      }
    },
  },
  methods: {
    copyLocation(){
      const location = this.location.replaceAll(" ","");
      if(!location){
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Copy`,
            icon: "CopyIcon",
            variant: "danger",
            text: `ไม่มีข้อมูล`,
          },
        });
        return
      }
      const url = `http://maps.google.com/maps?q=${location}`
      navigator.clipboard.writeText(url);
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: `Copy`,
          icon: "CopyIcon",
          variant: "success",
          text: `Copy url google map เรียบร้อย`,
        },
      });
    },
    copyJob() {
      var copyText = document.getElementById("jobPattern");
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices
      navigator.clipboard.writeText(copyText.value);
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: `Copy`,
          icon: "CopyIcon",
          variant: "success",
          text: `Copy ข้อความแจ้งงานเรียบร้อย`,
        },
      });
    },
    validateFlowCheckTime(type) {
      const date = this.ticketDate;

      if (type == "receive" && !date.open.status) {
        this.ticketDate.receive.status = false;
        alert("กรุณาเลือกวันที่เปิดใบงานก่อน");
        return;
      }

      if (type == "arrive" && !date.receive.status) {
        this.ticketDate.arrive.status = false;
        alert("กรุณาเลือกวันที่และเวลารับงานก่อน");
        return;
      }

      if (type == "close" && !date.arrive.status) {
        this.ticketDate.close.status = false;
        alert("กรุณาเลือกวันที่และเวลาแจ้งถึงก่อน");
        return;
      }
    },
    validateForm() {
      let form = this.ticketData;
      if (Object.keys(form.customer).length == 0) {
        alert("กรุณาเลือกสาขา");
        return true;
      }

      if (!form.problemDetail) {
        alert("กรุณากรอกปัญหา");
        return true;
      }

      if (form.ticketStatus.value == "close" && !form.problemSolving) {
        alert("กรุณากรอกวิธีแก้ไขปัญหา");
        return true;
      }

      if (form.receiveBy.userId == 0) {
        alert("กรุณาเลือกผู้เปิดใบงาน");
        return true;
      }

      if (form.assignTo.userId == 0) {
        alert("กรุณาเลือกผู้รับใบงาน");
        return true;
      }

      return false;
    },
    async onSaveTicket() {
      const hasError = this.validateForm();
      if (hasError) return;

      if (this.ticketData.ticketId) {
        await this.editTicket();
      } else {
        await this.insertTicket();
      }
    },

    async insertTicket() {
      try {
        if (
          this.ticketData.ticketStatus.value == "close" &&
          !this.canCloseTicketStatus
        ) {
          alert(
            "กรุณา Update สถานะการรับงาน แจ้งถึง และปิดงานให้ครบ ก่อนทำการเปลี่ยนสถานะใบงาน"
          );
          return;
        }

        this.isLoading.default = true;
        const obj = this.ticketData;
        const data = {
          ...this.ticketData,
          customerId: obj.customer.id,
          customerName: obj.customer.branchName,
          customerCode: obj.customer.branchCode,
          catLevel: obj.catLevel.value,
          catTime: obj.catTime.value,
          receiveBy: obj.receiveBy.userId,
          assignTo: obj.assignTo.userId,
          problemSolvingBy: obj.problemSolvingBy.value,
          ticketType: obj.ticketType.value,
          openTicketDate: GET_CURRENT_DATE().timestamp,
          receiveTicketDate: GET_CURRENT_DATE().timestamp,
          slaStatus: "WSLA",
          gasRate: obj.customer.gasRate,
          problemType: obj.problemType.value,
          ticketStatus: obj.ticketStatus.value,
        };

        const result = await TicketService.InsertTicket(data);
        if (result.status == "success") {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Ticket`,
              icon: "CheckIcon",
              variant: "success",
              text: `สร้างใบงานเรียบร้อย`,
            },
          });
          this.$router.push({ path: "/ticket/inprogress" });
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Ticket`,
              icon: "XIcon",
              variant: "danger",
              text: `พบปัญหาในการสร้างใบงาน`,
            },
          });
        }
        this.isLoading.default = false;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Ticket`,
            icon: "XIcon",
            variant: "danger",
            text: `พบปัญหาในการสร้างใบงาน`,
          },
        });
        console.log(error.message);
      }
    },

    async editTicket() {
      try {
        if (
          this.ticketData.ticketStatus.value == "close" &&
          !this.canCloseTicketStatus
        ) {
          alert(
            "กรุณา Update สถานะการรับงาน แจ้งถึง และปิดงานให้ครบ ก่อนทำการเปลี่ยนสถานะใบงาน"
          );
          return;
        }

        const obj = this.ticketData;
        this.isLoading.default = true;

        let data = {
          ...this.ticketData,
          customerId: obj.customer.id || obj.customerId,
          customerName: obj.customer.branchName,
          customerCode: obj.customer.branchCode,
          catLevel: obj.catLevel.value,
          catTime: obj.catTime,
          receiveBy: obj.receiveBy.userId,
          assignTo: obj.assignTo.userId,
          problemSolvingBy: obj.problemSolvingBy.value,
          ticketType: obj.ticketType.value,
          slaStatus: obj.slaStatus,
          gasRate: obj.customer.gasRate,
          problemType: obj.problemType.value,
          ticketStatus: obj.ticketStatus.value,
          assignToData: obj.assignTo,
        };

        
        data.problemDetail =  this.replaceNewTextBeforeSave(data.problemDetail || " ")
        data.problemSolving =  this.replaceNewTextBeforeSave(data.problemSolving || " ")
             
        console.log("data", data);
   

        

        if (
          !this.ticketDate.receive.readOnly &&
          this.ticketDate.receive.status
        ) {
          data.receiveTicketDate = GET_CURRENT_DATE().timestamp;
        }

        if (!this.ticketDate.arrive.readOnly && this.ticketDate.arrive.status) {
          data.arriveDate = GET_CURRENT_DATE().timestamp;
        }

        if (!this.ticketDate.close.readOnly && this.ticketDate.close.status) {
          data.closeTicketDate = GET_CURRENT_DATE().timestamp;
        }

        //Compute SLA
        let dateOne = moment(GET_CURRENT_DATE().timestamp).add(7, "h");
        let dateTwo = moment(this.ticketData.openTicketDate);
        let diff = dateOne.diff(dateTwo, "minutes") / 60;

        if (diff > obj.catTime) {
          data.slaStatus = "FSLA";
        }

        const userLoginData = localStorage.getItem("userData");
        const userData = JSON.parse(userLoginData);

        const ticketHistory = this.compareDataForSaveLog(data);
        const historyToString = ticketHistory.join();
        const result = await TicketService.EditTicket(
          this.ticketData.ticketId,
          data
        );

        TicketLogService.InsertLog({
          dataUpdate: historyToString,
          updateBy: userData.userId,
          refTicketId: this.ticketData.ticketId,
        });
        this.saveComment(data.remark);

        if (result.status == "success") {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Ticket`,
              icon: "CheckIcon",
              variant: "success",
              text: `แก้ไขใบงานเรียบร้อย`,
            },
          });
          this.$router.push({ path: "/ticket/inprogress" });
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Ticket`,
              icon: "XIcon",
              variant: "danger",
              text: `พบปัญหาในการสร้างใบงาน`,
            },
          });
        }
        this.isLoading.default = false;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Ticket`,
            icon: "XIcon",
            variant: "danger",
            text: `พบปัญหาในการแก้ไขใบงาน`,
          },
        });
        console.log(error.message);
      }
    },

    async saveComment(comment) {
      const userLoginData = localStorage.getItem("userData");
      const userData = JSON.parse(userLoginData);
      const commentObj = {
        tkCmnBy: userData.userId,
        refTicketId: this.ticketData.ticketId,
        detail: comment,
      };

      const lastComment =
        this.commentList && this.commentList[this.commentList.length - 1];
      const isDuplicate =
        lastComment && lastComment.detail == comment ? true : false;

      if (!isDuplicate) {
        await CommentService.InsertComment(commentObj);
      }
    },

    replaceNewTextBeforeSave(text=""){
    const htmlTagRegex = /<[^>]*>/g;
    let sanitizedInput = text.replace(htmlTagRegex, match => {
        return '(' + match.slice(1, -1) + ')';
    });

    const quoteRegex = /['"]/g;
    sanitizedInput = sanitizedInput.replace(quoteRegex, " ");

    return sanitizedInput; 
    },
    onSetup() {
      if (!this.ticketData.assignTo) {
        alert("กรุณาเลือกผู้รับใบงานก่อนทำการ Setup");
        return;
      }

      this.$bvModal.show("setup-modal");
      this.isShowSetup = !this.isShowSetup;
    },

    onMoveback() {
      if (!this.ticketData.assignTo) {
        alert("กรุณาเลือกผู้รับใบงานก่อนทำการ Moveback");
        return;
      }
      this.$bvModal.show("moveback-modal");
    },
    async getCustomerOwnerList() {
      try {
        const result = await CustomerService.GetCustomerOwnerList({});
        this.customerOption = [
          { id: 0, ownerName: "=== เลือกลูกค้า ===" },
          ...result.data.data,
        ];       
       

        //IF Have Role Permission For Customer
        if(this.customerRolePermission){
          if(Object.keys(this.customerRolePermission).length > 0 && this.customerRolePermission.ownerId !==0){
            console.log("permission=>>")
            this.customerOption = this.customerOption.filter(option=>option.id == this.customerRolePermission.ownerId)
          }
        }

        this.customerSelected = this.customerOption[0];
      } catch (error) {
        console.log("can't get customer owner");
      }
    },
    async getCustomerCompanyList() {
      try {
        if (!this.customerSelected.id && this.customerSelected.id == 0) return;
        this.isLoadCustomer = true;
        const result = await CustomerService.GetCustomerCompanyList(
          this.customerSelected.id,
          {}
        );
      
        this.customerCompanyList = [
          { id: 0, companyName: "=== เลือกบริษัท ===" },
          ...result.data.data,
        ];

        //IF Have Role Permission For Customer
        if(this.customerRolePermission){
          if(Object.keys(this.customerRolePermission).length > 0 && this.customerRolePermission.companyId !==0){
            this.customerCompanyList = this.customerCompanyList.filter(option=>option.id == this.customerRolePermission.companyId)
          }
        }

        this.isLoadCustomer = false;
      } catch (error) {
        console.log("can't get customer company");
      }
    },

    async getEmployeeList() {
      try {
        const result = await UserService.GetUserList({}, "");
        this.employeeOption = [
          { userId: 0, fullname: "=== เลือกพนักงาน ===" },
          ...result.data.data,
        ];
        this.ticketData.receiveBy = this.employeeOption[0];

        if (this.mode == "create") {
          this.ticketData.assignTo = this.employeeOption[0];
        }
      } catch (error) {
        console.log("can't get employee list");
      }
    },
    async getTicketCommentList(ticketId) {
      try {
        this.isLoading.comment = true;
        const result = await CommentService.GetCommentList(ticketId, {});
        this.commentList = result.data;
        this.isLoading.comment = false;
      } catch (error) {
        console.log("can't get comment owner");
      }
    },
    async getTicketLogList(ticketId) {
      try {
        this.isLoading.log = true;
        const result = await TicketLogService.GetLogList(ticketId, {});
        this.logList = result.data.filter(f=>f.dataUpdate!=="");
        this.isLoading.log = false;
      } catch (error) {
        console.log("can't get ticket log");
      }
    },
    async getTicketById(ticketId) {
      try {
        const result = await TicketService.GetTicketById(ticketId, {});
        this.convertDataToForm(result.data);
        if (this.ticketData.ticketStatus.value == "close") {
          this.mode = "close";
          this.ticketData.isNotSendMail = true
        } else if (this.ticketData.ticketStatus.value == "cancel") {
          this.ticketData.isNotSendMail = true
          this.mode = "cancel";
        }
      } catch (error) {
        console.log("error=>", error);
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Ticket`,
            icon: "XIcon",
            variant: "danger",
            text: `พบปัญหาในการเรียกข้อมูลใบงาน`,
          },
        });
      }
    },

    convertDataToForm(item) {
      const customerOwner = this.findDataInList(
        this.customerOption,
        "id",
        item.ownerId
      );
      const catLevel = this.findDataInList(
        this.catLevelOption,
        "value",
        item.catLevel
      );
      const receiveBy =
        this.findDataInList(this.employeeOption, "userId", item.receiveBy) ||
        this.employeeOption[0];
      const assignTo =
        this.findDataInList(this.employeeOption, "userId", item.assignTo) ||
        this.employeeOption[0];
      const problemType = this.findDataInList(
        this.problemTypeOption,
        "id",
        item.problemType
      );
      const ticketStatus = this.findDataInList(
        this.ticketStatusOption,
        "value",
        item.ticketStatus
      );

      let ticketData = {
        ...item,
        customer: {
          branchCode: item.customerCode,
          branchName: item.branchName,
          address: item.address,
          location: item.location,
          gasRate: item.gasRate,
          warrantyExpireDate:item.warrantyExpireDate,
          remark:item.branchRemark
        },
        problemSolvingBy: { title: item.problemSolvingBy },
        problemType,
        catLevel: { title: catLevel.title, value: catLevel.value },
        ticketType: { title: item.ticketType, value: item.ticketType },
        catTime: { title: item.catTime, value: item.catTime },
        receiveBy,
        assignTo,
        ticketStatus,
      };

      this.ticketDate = {
        open: {
          status: item.openTicketDate ? true : false,
          value: item.openTicketDate,
          readOnly: true,
        },
        receive: {
          status: item.receiveTicketDate ? true : false,
          value: item.receiveTicketDate ? item.receiveTicketDate : new Date(),
          readOnly: item.receiveTicketDate ? true : false,
        },
        arrive: {
          status: item.arriveDate ? true : false,
          value: item.arriveDate ? item.arriveDate : new Date(),
          readOnly: item.arriveDate ? true : false,
        },
        close: {
          status: item.closeTicketDate ? true : false,
          value: item.closeTicketDate ? item.closeTicketDate : new Date(),
          readOnly: item.closeTicketDate ? true : false,
        }        
      };
     

      this.customerSelected = customerOwner;
      this.ticketData = ticketData;
      INIT_TICKET = { ...ticketData };
    },

    findDataInList(list, field, value) {
      const finded = list.find((f) => f[field] == value);
      return finded;
    },

    showDate(date = new Date(), isSubtract, dateonly=false) {
      if (!date) {
        return moment().format("DD-MM-yyyy HH:mm");
      }
      if (dateonly) {
        return moment().format("DD-MM-yyyy");
      }

      if (isSubtract) {
        return moment(date).subtract(7, "hours").format("DD-MM-yyyy HH:mm");
      }
      return moment(date).format("DD-MM-yyyy HH:mm");
    },

    compareDataForSaveLog(newData) {
      let newUpdateList = [];

      this.checkEditData(newData, "ticketNo", newUpdateList);
      this.checkEditData(newData, "customerCode", newUpdateList);
      this.checkEditData(newData, "refTicketNo", newUpdateList);
      this.checkEditData(newData, "isSendMsr", newUpdateList);
      this.checkEditData(newData, "msrNo", newUpdateList);
      this.checkEditData(newData, "contactName", newUpdateList);
      this.checkEditData(newData, "contactTel", newUpdateList);
      this.checkEditData(newData, "problemDetail", newUpdateList);
      this.checkEditData(newData, "problemSolving", newUpdateList);
      this.checkEditData(newData, "remark", newUpdateList);
      this.checkEditData(newData, "openTicketDate", newUpdateList);
      this.checkEditData(newData, "receiveTicketDate", newUpdateList);
      this.checkEditData(newData, "arriveDate", newUpdateList);
      this.checkEditData(newData, "closeTicketDate", newUpdateList);
      this.checkEditData(newData, "slaStatus", newUpdateList);
      this.checkEditData(newData, "receiveBy", newUpdateList);
      this.checkEditData(newData, "assignTo", newUpdateList);

      this.checkEditData(newData, "catLevel", newUpdateList);
      this.checkEditData(newData, "catTime", newUpdateList);
      this.checkEditData(newData, "problemType", newUpdateList);
      this.checkEditData(newData, "problemSolvingBy", newUpdateList);
      this.checkEditData(newData, "ticketType", newUpdateList);
      this.checkEditData(newData, "ticketStatus", newUpdateList);

      return newUpdateList;
    },

    checkEditData(newData, field, output) {
      let oldData = INIT_TICKET;
      let customeList = [
        "catLevel",
        "catTime",
        "receiveBy",
        "assignTo",
        "problemType",
        "problemSolvingBy",
        "ticketType",
        "ticketStatus",
      ];
      let specialCustomeList = ["receiveBy", "assignTo"];
      if (field == "catTime") {
      }

      if (customeList.includes(field)) {
        //CUSTOME BY ID
        if (specialCustomeList.includes(field)) {
          if (oldData[field] && oldData[field].userId !== newData[field]) {
            output.push(`-${field}`);
          }
        } else {
          //CUSTOMER BY VALUE
          if (oldData[field].value !== newData[field]) {
            output.push(`-${field}`);
          }
        }
      } else {
        //NONE OBJECT
        if (oldData[field] !== newData[field]) {
          output.push(`-${field}`);
        }
      }
    },

    onSelectedBranch(item) {
      this.ticketData.customer = {
        location: `${item[0].latitude} ${item[0].longtitude}`,
        ...item[0],
      };
    },

    onClickFindCustomerModal() {
      if (this.customerSelected.id == 0) {
        return;
      }

      if (this.isLoadCustomer || this.ticketPermission !== "edit") {
        return;
      }
      this.$bvModal.show("select-customer-modal");
    },

    checkExpireWarranty(datetime){
      if(datetime=="0000-00-00"){
        return ""
      }
      
      const now = moment(new Date()); //todays date
      const end = moment(datetime); // another date
      const duration = moment.duration(end.diff(now));
      const days = duration.asDays();
      
      if(days <0 ){
        this.isWarrantyExpire = true;
        return "*** หมดประกันแล้ว ***"
      }
      
      if(days>0){
        this.isWarrantyExpire = false;
        return "อยู่ในประกัน"
      }
      
      return ""
      
    }
  },
};
</script>

<style scoped>
>>> .input-group-prepend .input-group-text {
  border: 1px solid #ccc !important;
}



 .dark-layout input.form-control:disabled, .dark-layout input.form-control[readonly=readonly], .dark-layout .custom-file-label:disabled, .dark-layout .custom-file-label[readonly=readonly], .dark-layout textarea.form-control:disabled, .dark-layout textarea.form-control[readonly=readonly]{
  opacity: 1;
}



</style>