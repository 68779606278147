import { BaseUrl } from '../ApiUrl'
import AXIOS_SERVICE from '../../utils/AxiosUtil'

const MovebackStockService = () => {}

MovebackStockService.CheckStockBySerial = async(serial)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/stock/moveback/search/${serial}`, {})
}

MovebackStockService.InsertMovebackStock = async(payload)=> {  
  return await AXIOS_SERVICE('post', `${BaseUrl}/stock/moveback`, payload)
}

 export default MovebackStockService