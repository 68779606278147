<template>
  <div>
    <b-modal
      ref="changeDate-modal"
      id="changeDate-modal"
      title="แก้ไขวันที่และเวลา"
      size="lg"
      hide-footer
    >
      <b-card-text>

        <b-row v-if="dateData.open">
          <b-col md="4" class="">
            <label>วันที่เปิดใบงาน</label>
            <b-form-input
              id="smallInput"
              size="sm"
              placeholder="วันที่เปิดใบงาน"
              readonly              
              :value="convertDate(ticketData.openTicketDate, 'original')"
            />
          </b-col>
          <b-col md="4" class="">
            <label>วันที่</label>
            <b-form-datepicker
            id="openTicketDate"
            locale="th-TH"
            show-decade-nav
            class="mb-1"
            size="sm"
           v-model="dateData.open.date"
          />
        </b-col>
          <b-col md="4" class="">
            <label>เวลา</label>
            <b-form-timepicker id="timepicker-open" size="sm" locale="th-TH" class="mb-2" v-model="dateData.open.time"></b-form-timepicker>
          </b-col>   
        </b-row>

        <b-row v-if="dateData.receive">
          <b-col md="4" class="">
            <label>วันที่รับงาน</label>
            <b-form-input
              id="smallInput"
              size="sm"
              placeholder="วันที่รับงาน"
              readonly              
              :value="convertDate(ticketData.receiveTicketDate, 'original')"
            />
          </b-col>
          <b-col md="4" class="">
            <label>วันที่</label>
            <b-form-datepicker
            id="receiveTicketDate"
            locale="th-TH"
            show-decade-nav
            class="mb-1"
            size="sm"           
           v-model="dateData.receive.date"
          />
          </b-col>
          <b-col md="4" class="">
            <label>เวลา</label>
            <b-form-timepicker id="timepicker-receive" size="sm" locale="th-TH" class="mb-2" v-model="dateData.receive.time"></b-form-timepicker>
          </b-col>   
        </b-row>

        <b-row v-if="dateData.arrive">
          <b-col md="4" class="">
            <label>วันที่แจ้งถึง</label>
            <b-form-input
              id="smallInput"
              size="sm"
              placeholder="วันที่แจ้งถึง"
              readonly
              :value="convertDate(ticketData.arriveDate, 'original')"
            />
          </b-col>
          <b-col md="4" class="">
            <label>วันที่</label>
            <b-form-datepicker
            id="arriveDate"
            locale="th-TH"
            show-decade-nav
            class="mb-1"
            size="sm"
            v-model="dateData.arrive.date"
          />
          </b-col>
          <b-col md="4" class="">
            <label>เวลา</label>
            <b-form-timepicker id="timepicker-arrive" size="sm" locale="th-TH" class="mb-2" v-model="dateData.arrive.time"></b-form-timepicker>
          </b-col>  
        </b-row>

        <b-row v-if="dateData.close">
          <b-col md="4" class="">
            <label>วันที่ปิดใบงาน</label>
            <b-form-input
              id="smallInput"
              size="sm"
              placeholder="วันที่ปิดใบงาน"
              readonly             
              :value="convertDate(ticketData.closeTicketDate, 'original')"
            />
          </b-col>
          <b-col md="4" class="">
            <label>วันที่</label>
            <b-form-datepicker
            id="closeTicketDate"
            locale="th-TH"
            show-decade-nav
            class="mb-1"
            size="sm"
           v-model="dateData.close.date"
          />
          </b-col>
          <b-col md="4" class="">
            <label>เวลา</label>
            <b-form-timepicker id="timepicker-close" size="sm" locale="th-TH" class="mb-2" v-model="dateData.close.time"></b-form-timepicker>
          </b-col>  
        </b-row>
    

 

        <!--- START (BTN SUBMIT) --->
        <b-row class="mt-1">
          <b-col md="12" class="d-flex justify-content-end">
            <b-button variant="primary" size="sm"  :disabled="isLoading " @click="save"
              >
              <span v-if="isLoading">กำลังบันทึก...</span>
              <span v-else>บันทึก</span>
              </b-button
            >
          </b-col>
        </b-row>
        <!--- END (BTN SUBMIT) --->
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
//SERVICES
import TicketService from "@/services/ticket";

import {
  VBModal,
  BCardText,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormDatepicker,
  BFormTimepicker 
} from "bootstrap-vue";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { GET_CURRENT_DATE } from "@/utils/";
import moment from "moment";

export default {
  props: ["ticketData"],
  components: {
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BFormDatepicker,
    BFormTimepicker 
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      dateData:{open:null, receive:null, arrive:null, close:null},
      userLogin: {},    
      isLoading:false
    };
  },
  async created() {
    const userLoginData = localStorage.getItem("userData");
    const userData = JSON.parse(userLoginData);    
    this.userLogin = userData;    
  },

  watch: {
    ticketData(newValue, oldValue) {
      this.convertTicketDate()
    },
  },

  methods: {
    async save(){
      
      let dateData = {}

      if(this.combineDate("open")){
        dateData.openTicketDate = this.combineDate("open")
      }

      if(this.combineDate("receive")){
        dateData.receiveTicketDate = this.combineDate("receive")
      }

      if(this.combineDate("arrive")){
        dateData.arriveDate = this.combineDate("arrive")
      }

      if(this.combineDate("close")){
        dateData.closeTicketDate = this.combineDate("close")
      }

      const checkDate = this.validateDate(dateData)
      if(checkDate) return
     
      this.isLoading = true
      const result = await TicketService.EditTicket(
          this.ticketData.ticketId,
          dateData
      );
      if (result.status == "success") {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Ticket`,
              icon: "CheckIcon",
              variant: "success",
              text: `แก้ไขใบงานเรียบร้อย`,
            },
          });
          this.$router.push({ path: "/ticket/inprogress" });
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Ticket`,
              icon: "XIcon",
              variant: "danger",
              text: `พบปัญหาในการสร้างใบงาน`,
            },
          });
        }
        this.isLoading = false
    },
    convertDate(date, type = "") {
      if (type == "date") {
        return moment(date).format("DD-MM-yyyy");
      }

      if(type=="original"){
        return moment(date).subtract(7, 'h').format("DD-MM-yyyy HH:mm:ss");
      }

      if(type=="subtract"){
        return moment(date).subtract(7, 'h')
      }

      return moment(date).format("DD-MM-yyyy HH:mm:ss");
    },
    convertTicketDate(){
      const ticket  = this.ticketData      
      
      if(ticket.openTicketDate){
        const open = this.convertDate(ticket.openTicketDate, "subtract")
        this.dateData.open ={
          date:moment(open).format("yyyy-MM-DD"),
          time:moment(open).format("HH:mm")
        }
      }

      if(ticket.receiveTicketDate){
        const receive = this.convertDate(ticket.receiveTicketDate, "subtract")
        this.dateData.receive ={
          date:moment(receive).format("yyyy-MM-DD"),
          time:moment(receive).format("HH:mm")
        }
      }

      if(ticket.arriveDate){
        const arrive = this.convertDate(ticket.arriveDate, "subtract")
        this.dateData.arrive ={
          date:moment(arrive).format("yyyy-MM-DD"),
          time:moment(arrive).format("HH:mm")
        }
      }

      if(ticket.closeTicketDate){
        const close = this.convertDate(ticket.closeTicketDate, "subtract")
        this.dateData.close ={
          date:moment(close).format("yyyy-MM-DD"),
          time:moment(close).format("HH:mm")
        }
      }
    },
    combineDate(type){
      const dateData = this.dateData
      if(dateData[type]){
        const date =  new Date(`${dateData[type].date} ${dateData[type].time}`)
        return GET_CURRENT_DATE(date).timestamp   
      }
    },
    validateDate(dateData){
      if(moment(dateData.openTicketDate) > moment(dateData.receiveTicketDate)){
        alert("กรุณาตรวจสอบวันและเวลาของ #การรับงาน เนื่องจากมีวันที่หรือเวลาน้อยกว่าวันเปิดใบงาน")
        return true
      }
      if(moment(dateData.receiveTicketDate) > moment(dateData.arriveDate)){
        alert("กรุณาตรวจสอบวันและเวลาของ #การแจ้งถึง เนื่องจากมีวันที่หรือเวลาน้อยกว่าวันรับงาน")
        return true
      }
      if(moment(dateData.arriveDate) > moment(dateData.closeTicketDate)){
        alert("กรุณาตรวจสอบวันและเวลาของ #การปิดงาน เนื่องจากมีวันที่หรือเวลาน้อยกว่าวันแจ้งถึง")
        return true
      }
    },
    hideModal() {
      this.$refs["changeDate-modal"].hide();
    },
  },

};
</script>

<style>
@import "./style.css";
</style>
