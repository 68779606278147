import { BaseUrl } from './ApiUrl'
import AXIOS_SERVICE from '../utils/AxiosUtil'

const CommentService = () => {}

CommentService.GetCommentList = async(ticketId, payload)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/ticket/comment/${ticketId}`, payload)
 }

 CommentService.InsertComment = async(payload)=> {  
  return await AXIOS_SERVICE('post', `${BaseUrl}/ticket/comment`, payload)
 } 
 
 export default CommentService