import { BaseUrl } from './ApiUrl'
import AXIOS_SERVICE from '../utils/AxiosUtil'

const TicketLogService = () => {}

TicketLogService.GetLogList = async(ticketId, payload)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/ticket/log/${ticketId}`, payload)
 }

 TicketLogService.InsertLog = async(payload)=> {  
  return await AXIOS_SERVICE('post', `${BaseUrl}/ticket/log`, payload)
 } 
 
 export default TicketLogService